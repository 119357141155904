@import 'src/styles/variables';
@import 'src/styles/mixins';

.variableContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.basePriceContainer {
  padding-bottom: 10px;
  border-bottom: 1px solid $primary20;
}

.basePrice {
  font-family: $montserratFontStyle;
  font-weight: $boldFontWeight;
  letter-spacing: -0.2px;
  color: $black100;
  @include grossFont;
}

.brokerPaidCompValue {
  font-family: $montserratFontStyle;
  font-weight: $boldFontWeight;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: $red100;
}

.brokerPaidZeroValue {
  color: $black100;
}

.defaultVariableName {
  font-family: $montserratFontStyle;
  font-weight: $regularFontWeight;
  letter-spacing: -0.7px;
  color: $black100;
  @include smallFont;
}

.sectionTitle {
  font-family: $montserratFontStyle;
  font-weight: $boldFontWeight;
  letter-spacing: -0.5px;
  color: $black100;
  margin-bottom: 4px;
  @include smallFont;
}

.priceAdjustmentValue {
  font-family: $montserratFontStyle;
  font-weight: $boldFontWeight;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: $green100;
  font-variant-numeric: tabular-nums;
}

.priceAdjustmentValueNegative {
  color: $red100;
}

.netPriceContainer {
  padding-top: 16px;
  border-top: 1px solid $primary20;
}

.pointsContainer {
  padding-top: 12px;
}

.netPrice {
  font-family: $montserratFontStyle;
  font-weight: $semiBoldFontWeight;
  letter-spacing: -0.5px;
  color: $black100;
  @include middleFont;
}

.defaultVariablesContainer {
  padding: 16px 0;
  font-variant-numeric: tabular-nums;
}

.brokerPaidCompValueContainer {
  margin-bottom: 12px;
}

@media print {
  .basePrice {
    @include middleFont;
  }

  .netPrice {
    @include smallFont;
  }
}
