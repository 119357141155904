@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  @include variablesBoxContainer;

  &:hover {
    border: 1px solid $primary40;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 20px;

  @include xl {
    padding: 26px 32px;
  }
}

.tileLeftBar {
  display: flex;
  align-items: center;
}

.selectArrow {
  transform-origin: center;
  transition: 0.2s ease-in-out;

  & > path {
    fill: $primary100;
  }
}

.selectArrowActive {
  transform: rotate(180deg)
}

.title {
  @include h3;
  color: $primary100;

  @include sm {
    margin-right: 40px;
  }
}

.content {
  padding: 0 20px;
  box-sizing: border-box;

  @include xl {
    padding: 0 32px;
  }
}

.desktopEditDataButton {
  display: none;

  @include sm {
    display: flex;
  }
}

.mobilEditDataButton {
  width: 100%;
  padding: 8px 16px;
  margin-bottom: 20px;

  @include sm {
    display: none;
  }
}

.desktopEditDataIcon {
  margin-right: 8px;
  
  & stop:first-child {
    stop-color: $gradientFirstColor;
  }
  & stop:last-child {
    stop-color: $gradientSecondColor;
  }
}

.mobilEditDataIcon {
  margin-right: 8px;
}

.sectionsContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  padding-bottom: 20px;

  @include xl {
    padding-bottom: 32px;
  }
}

.contentSection {
  padding-top: 16px;
  border-top: 1px solid $primary20;

  @include xl {
    padding-top: 24px;
  }
}

.sectionTitle {
  font-family: $ralewayFontStyle;
  font-weight: $boldFontWeight;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: $primary100;
  margin-bottom: 16px;
  @include grossFont;

  @include lg {
    @include hugeFont;
  }
}

.variablesContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 20px;

  @include sm {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 15px;
  }

  @include xl {
    grid-template-columns: repeat(5, 1fr);
  }
}
