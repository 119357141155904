@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  position: relative;
  display: block;
  padding-left: 28px;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
}

.checkboxLabel {
  color: $black100;
  font-family: $montserratFontStyle;
  letter-spacing: -0.5px;
  @include middleFont;
}

.container input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px solid $primary100;
  background-color: transparent;
}

.checkboxCheckmark {
  border-radius: 4px;
}

.radioButtonCheckmark {
  border-radius: 50%;
}

.container:hover input ~ .checkmark {
  border: 1px solid $primary100;
  background: $primary2;
  transition: 0.3s;
}

.container input:checked ~ .checkmark {
  background-color: transparent;
}

.container input:checked ~ .checkmarkIcon {
  display: block
}

.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark::after {
  display: block;
}

.container .radioButtonCheckmark::after {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $primary100;
}

.checkmarkIcon {
  display: none;
  position: absolute;
  left: 1px;
  top: 1px;
  & > path {
    fill: $primary100;
  }
}
