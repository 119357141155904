@import 'src/styles/variables';
@import 'src/styles/mixins';

$selectedRowBackgroundPrint: #511EC7;

.tableHeader {
  display: flex;
}

.titleContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  font-family: $montserratFontStyle;
  font-weight: $boldFontWeight;
  letter-spacing: -0.5px;
  color: $black60;
  cursor: pointer;
  @include smallFont;
}

.arrowRotated {
  transform: rotate(180deg);
}

.rowContainer {
  font-family: $montserratFontStyle;
  font-weight: $regularFontWeight;
  letter-spacing: -0.5px;
  flex-grow: 1;
  font-variant-numeric: tabular-nums;
  @include grossFont;
  display: flex;
}

.rowDataContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  @include productTableRowContainer;
  position: relative;


  &:hover {
    background: $primary6;
  }
}

.bestPriceLabel {
  margin-left: 10px;
}

.bestPriceLabelRetail {
  position: absolute;
  top: 0;
  left: 2px;
  padding: 0 6px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.rowDataContainerSelected {
  &:hover {
    background: $primary6;
  }

  @include md {
    background: $gradient;
    color: $white;

    .bestPriceLabel {
      background: rgba(255, 255, 255, 0.2);
    }

    &:hover {
      background: $gradient;
    }
  }
}

.rowDataContainerSelectedMobil {
  background: $gradient;
  color: $white;

  &:hover {
    background: $gradient;
  }

  .bestPriceLabel {
    background: rgba(255, 255, 255, 0.2);
  }
}

@media print {
  .rowDataContainer {
    padding: 7px 5px;
  }

  .bestPriceLabel {
    padding: 1px 4px;
  }

  .titleContainer {
    margin-bottom: 8px;
  }

  .rowDataContainerSelected {
    background-color: $selectedRowBackgroundPrint;
    color: $white;
  }
}

.negativePoint {
  color: $green100;
}

.negativePointCurrent {
  color: $green100;

  @include md {
    color: $white;
  }
}
