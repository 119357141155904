@import 'src/styles/variables';
@import 'src/styles/media';
@import 'src/styles/mixins';

.innerPageLayout {
  padding: 0 32px;

  @include xl {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.container {
  padding-top: 8px;
  padding-bottom: 30px;

  @include xl {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.errorNumber {
  font-family: $montserratFontStyle;
  font-weight: $semiBoldFontWeight;
  font-size: 100px;
  line-height: 122px;
  text-align: center;
  color: $primary100;
  margin-bottom: 24px;

  @include xl {
    font-size: 140px;
    line-height: 171px;
  }
}

.errorTitle {
  @include h3;
  margin-bottom: 16px;
  text-align: center;
}

.errorSubtitle {
  font-family: $montserratFontStyle;
  font-weight: $regularFontWeight;
  text-align: center;
  letter-spacing: -0.5px;
  color: $black80;
  margin-bottom: 16px;
  @include middleFont;

  @include xl {
    @include grossFont;
  }
}

.homeLink {
  background: $gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: $montserratFontStyle;
  font-weight: $semiBoldFontWeight;
  text-align: center;
  letter-spacing: -0.2px;
  cursor: pointer;

  @include grossFont;
}
