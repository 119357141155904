@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/media';

.disclaimerBox {
  display: grid;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  background: $primary10;
  border-radius: 4px;
  padding: 32px;
  margin: 40px 0 0;
  @include paragraph;

  @include xl {
    max-width: 63.5%;
    margin: 40px 32px 0;
  }
}

.disclaimerTitle {
  font-weight: $boldFontWeight;
}

.listTypeDisclaimer:before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #000E24;
  margin-right: 10px;
}
