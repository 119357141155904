@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  background: linear-gradient(160.65deg, rgba(248, 250, 252, 0.5) 46.35%, #E3EAF2 100%), $white;
  box-sizing: border-box;

  @include xl {
    height: 100vh;
    width: 33vw;
    position: fixed;
    left: 0;
    top: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  height: 100%;
  padding: 0 32px 56px;

  @include xl {
    padding: 48px 32px 0;
    box-sizing: border-box;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding-top: 8px;

  @include xl {
    padding-top: 24px;
    max-width: 360px;
    align-self: center;
  }
}

.switcherTitle {
  @include h4;
  color: $black100;
  margin-bottom: 16px;
}

.radioButton {
  @include xl {
    max-width: 360px;
  }
}

.radioButton:not(:last-child) {
  margin-bottom: 24px;
}

.topBar {
  @include xl {
    flex-grow: 1;
    max-width: 360px;
    align-self: center;
  }
}
