@import 'src/styles/mixins';

.container {
  padding: 16px 32px 64px;
  max-width: 1352px;
  margin: 0 auto;

  @include xl {
    padding: 64px 32px 72px;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include xl {
    padding: 0 32px;
  }

  @include sm {
    display: block;
  }
}

.backLink {
  margin-bottom: 24px;
}

.header {
  display: none;
  margin-bottom: 32px;
  @include h1;

  @include sm {
    display: block;
  }
}

.headerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.printButton {
  margin-bottom: 24px;

  @include sm {
    margin-bottom: 0;
  }
}

.printButtonIcon {
  & stop:first-child {
    stop-color: $gradientFirstColor;
  }
  & stop:last-child {
    stop-color: $gradientSecondColor;
  }
}

.termsBoxContainer {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  margin-bottom: 40px;
  @include variablesBoxContainer;

  @include sm {
    padding: 32px;
    grid-template-columns: 30% 50% 20%;
    grid-column-gap: 30px;
    margin-bottom: 56px;
  }

  @include lg {
    grid-template-columns: 20% 30% 10%;
  }
}

.contentContainer {
  @include xl {
    padding: 0 32px;
  }
}

.contentTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @include sm {
    justify-content: flex-start;
  }
}

.contentTitle {
  @include h3;
  color: $primary100;

  @include sm {
    margin-right: 40px;
  }
}

.contentSectionTitle {
  font-family: $montserratFontStyle;
  font-weight: $boldFontWeight;
  letter-spacing: -0.2px;
  color: $black100;
  margin-bottom: 16px;
  @include grossFont;
}

.pricingContainer {
  @include md {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10%;
  }

  @include xl {
    width: 93%;
  }
}

.singlePricingContainer {
  @extend .pricingContainer;

  @include md {
    grid-template-columns: 1.1fr 0.9fr;
  }
}

.content:not(:last-child) {
  margin-bottom: 40px;

  @include md {
    margin-bottom: 0;
  }
}

.buttonsContainer {
  display: flex;
}

.filterButton {
  min-width: 140px;
}

.gradientFilterIcon {
  & stop:first-child {
    stop-color: $gradientFirstColor;
  }
  & stop:last-child {
    stop-color: $gradientSecondColor;
  }
}

.resetButton {
  display: none;
  min-width: 80px;
  margin-left: 8px;

  @include sm {
    display: flex;
  }
}

@media print {
  .contentSectionTitle {
    margin-bottom: 9px;
  }
}
