@import 'src/styles/mixins';
@import 'src/styles/variables';

.inputSelectContainer {
  position: relative;
  padding-bottom: 22px;
}

.label {
  @include inputLabel;
  margin-bottom: 9px;
  color: $black60;
}

.dropdownInput {
  position: relative;
}

.inputSelect {
  padding: 16px 52px 16px 24px;
}

.dropdownOptionsList {
  @include dropdownOptionsList;
}

.selectArrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 24px;
  height: 24px;
  pointer-events: none;
  transition: 0.2s ease-in-out;
  transform: translateY(-50%);
  transform-origin: center;
}

.selectArrowActive {
  transition: 0.2s ease-in-out;
  transform: translateY(-50%) rotate(180deg);
}

.dropdownOption {
  @include dropdownOption;
}

.dropdownOptionSelected {
  color: $black100;
}

.checkIcon {
  position: absolute;
  top: 50%;
  right: 16px;
  pointer-events: none;
  transform: translateY(-50%);
}

.dropdownOptionContainer {
  position: relative;
}

.errorMessage {
  position: absolute;
  bottom: 0;
}

.errorLabel {
  color: $red100;
}

.error {
  border: 1px solid $red100;
}

@media print {
  .inputSelect {
    min-height: 27px;
    padding: 9px 13px;
    @include smallFont;
  }

  .label {
    margin-bottom: 5px;
    @include smallFont;
  }

  .inputSelectContainer {
    padding-bottom: 16px;
  }

  .selectArrow {
    right: 7px;
    width: 13px;
    height: 13px;
  }
}
