@import 'src/styles/variables';
@import 'src/styles/mixins';

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header {
  @include h3;
  color: $primary100;
}

.closeIcon {
  width: 24px;
  height: auto;
  cursor: pointer;
}
