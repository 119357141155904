@import 'src/styles/mixins';

.productHeader {
  @include productTableHeader;
}

.productsContainer {
  @include productsContainer;
  font-variant-numeric: tabular-nums;
}

