@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  background: $white;
  border: 1px solid $primary20;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 20px 12px;

  @include lg {
    @include productTableRowContainer;
    display: grid;
    grid-template-columns: 24% 76%;
  }
}

.variableName {
  @include productTableVariableName;
}

.variableValue {
  @include productTableVariableValue;
}

.declineReasonValue {
  @include paragraph;
  font-weight: $regularFontWeight;

  @include lg {
    @include productTableVariableValue;
  }
}

.container .variableContainer:not(:last-child) {
  margin-bottom: 12px;

  @include lg {
    margin-bottom: 0;
  }
}

