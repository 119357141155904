@import 'src/styles/mixins';
@import 'src/styles/variables';

.title {
  font-family: $montserratFontStyle;
  font-weight: $semiBoldFontWeight;
  letter-spacing: -0.5px;
  color: $black60;
  margin-bottom: 6px;
  @include middleFont;
}

.value {
  font-family: $montserratFontStyle;
  font-weight: $boldFontWeight;
  letter-spacing: -0.2px;
  color: $black100;
  @include grossFont;
}
