@import 'src/styles/mixins';
@import 'src/styles/variables';

.searchInput {
  padding: 16px 52px 16px 24px;
}

.label {
  @include inputLabel;
  margin-bottom: 9px;
  color: $black60;
}

.dropdownOptionsList {
  @include dropdownOptionsList;
}

.nothingFound {
  @include dropdownOptionsList;
  @include paragraph;
  padding: 16px;
  border-radius: 8px;
  font-weight: $regularFontWeight;
  color: $black100;
}

.dropdownInput {
  position: relative;
}

.dropdownOption {
  @include dropdownOption;
}

.searchIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  pointer-events: none;
}

.closeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: $primary10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  transition: 0.2s all ease-in-out;
  cursor: pointer;

  &:hover {
    background: $gradient;

    svg path {
      fill: $white;
    }
  }
}

.errorLabel {
  color: $red100;
}

.error {
  border: 1px solid $red100;
}

@media print {
  .searchInput {
    min-height: 27px;
    padding: 9px 13px;
    @include smallFont;
  }

  .label {
    @include smallFont;
    margin-bottom: 5px;
  }

  .closeIcon {
    right: 7px;
  }
}
