@import 'src/styles/variables';
@import 'src/styles/mixins';

.rangeTitle {
  font-family: $montserratFontStyle;
  font-weight: $semiBoldFontWeight;
  letter-spacing: -0.5px;
  color: $black100;
  margin-bottom: 14px;
  @include middleFont;
}

.rangeSliderContainer {
  padding: 0 16px;
}

.rangeValuesContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rangeSeparator {
  background-color: $black60;
  height: 2px;
  min-width: 8px;
  width: 8px;
  margin: 0 12px;
}
