@import 'src/styles/media';
@import 'src/styles/mixins';

.container {
  padding: 40px 32px 32px;
  width: 100%;
  box-sizing: border-box;

  @include xl {
    max-width: unset;
    padding: 64px 6% 16px calc(33vw + 6%)
  }
}

.formContainer {
  max-width: 564px;
  margin: 0 auto;

  @include xl {
    max-width: unset;
  }
}

.title {
  @include h1;
  margin-bottom: 24px;
}
