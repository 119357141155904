@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  position: relative;
  display: flex;
  cursor: pointer;
  user-select: none;
  background: $primary4;
  border: 1px solid $primary20;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 24px 16px;
  transition: 0.2s all ease-in-out;

  @include lg {
    padding: 24px;
  }
}

.container:hover {
  border: 1px solid $primary60;
}

.input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  padding: 4px;
  border: 1px solid rgba(0, 18, 47, 0.6);
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 50%;
  margin-right: 12px;
}

.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

.title {
  @include h4;
  color: $black60;
  margin-bottom: 8px;

  @include lg {
    margin-bottom: 12px;
  }
}

.subtitle {
  font-family: $montserratFontStyle;
  font-weight: $regularFontWeight;
  letter-spacing: -0.5px;
  color: $black60;
  @include smallFont;
}

.checkedContainer {
  background: $gradient;
  border: 1px solid rgba(255, 255, 255, 0.6);
  transition: 0.2s all ease-in-out;
}

.checkedTitle {
  color: $white;
}

.checkedSubtitle {
  color: rgba(255, 255, 255, 0.8);
}

.checkedCheckmark {
  background-color: transparent;
  border: 1px solid $white;
}

.checkedCheckmark::after {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $white;
}
