@import 'src/styles/mixins';

.buttonsContainer {
  @include sm {
    display: grid;
    grid-template-columns:  repeat(2, 1fr);
  }
}

.button {
  width: 100%;
  padding: 8px 16px;
  min-height: 40px;

  @include sm {
    width: 180px;
  }
}

.applyButton {
  margin-bottom: 16px;

  @include sm {
    margin-bottom: 0;
  }
}

.resetButton {
  @include sm {
    justify-self: flex-end;
  }
}
