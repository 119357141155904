@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  padding: 8px 32px 64px;
  max-width: 1352px;
  margin: 0 auto;

  @include lg {
    padding: 64px 32px 72px;
  }
}

.topBar {
  display: flex;
  flex-direction: column-reverse;

  @include lg {
    flex-direction: column;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @include lg {
    margin-bottom: 24px;
    padding: 0 32px;
  }
}

.title {
  @include h1;
}

.newRequestButton {
  display: none;
  min-width: 234px;

  @include lg {
    display: flex;
  }
}

.newRequestButtonMobil {
  width: 100%;
  margin: 32px auto 0;
  max-width: 311px;

  @include lg {
    display: none;
  }
}

.dropdown {
  margin-bottom: 40px;

  @include lg {
    margin-bottom: 62px;
  }
}

.contentContainer {
  @include lg {
    padding: 0 32px;
  }
}

.contentTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @include sm {
    justify-content: flex-start;
  }

  @include lg {
    margin-bottom: 36px;
  }
}

.contentTitle {
  @include h3;
  color: $primary100;

  @include sm {
    margin-right: 40px;
  }
}

.sectionContainer {
  margin-bottom: 48px;

  @include lg {
    margin-bottom: 72px;
  }
}

.notAvailableProductsTitle {
  margin-bottom: 16px;

  @include lg {
    margin-bottom: 36px;
  }
}

.buttonsContainer {
  display: flex;
}

.gradientFilterIcon {
  & stop:first-child {
    stop-color: $gradientFirstColor;
  }
  & stop:last-child {
    stop-color: $gradientSecondColor;
  }
}

.filterButton {
  min-width: 140px;
}

.resetButton {
  display: none;
  min-width: 80px;
  margin-left: 8px;

  @include sm {
    display: flex;
  }
}
