@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  position: relative;
  display: flex;
  cursor: pointer;
}

.label {
  position: absolute;
  min-width: 272px;
  left: -50%;
  transform: translate(-50%, -100%);
  top: -10px;
  display: none;
  background: $gradient;
  box-shadow: 0 8px 24px -6px rgba(0, 55, 140, 0.16), 0px 0px 1px rgba(0, 55, 140, 0.4);
  border-radius: 12px;
  padding: 16px;
  font-family: $montserratFontStyle;
  font-weight: $regularFontWeight;
  letter-spacing: -0.7px;
  color: $white;
  @include smallFont;

}

.container:hover {
  .label {
    display: block;
  }
}
