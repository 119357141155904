@import 'src/styles/variables';
@import 'src/styles/mixins';

.sectionTitle {
  @include h3;
  color: $black100;
  margin-bottom: 16px;
}

.formInput:not(:last-child) {
  margin-bottom: 8px;
}

.countyInput {
  margin-top: 22px;
}
