@import 'src/styles/variables';
@import 'src/styles/mixins';

.inputError {
  font-family: $montserratFontStyle;
  font-weight: $regularFontWeight;
  letter-spacing: -0.7px;
  color: $red100;
  margin-top: 4px;
  @include smallFont;
}
