@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  border-bottom: 1px solid $primary20;
  border-radius: 0;
  padding: 0 8px 12px;
  display: grid;
  grid-template-columns: $productsTableGridColumnSize;
}

.retailPortalContainer {
  @extend .container;
  grid-template-columns: $productsTableGridColumnSize;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.title {
  font-family: $montserratFontStyle;
  font-weight: $boldFontWeight;
  letter-spacing: -0.5px;
  color: $black60;
  cursor: pointer;
  @include smallFont;
}

.currentSortingTitle {
  color: $black100;
}

.arrowRotated {
  transform: rotate(180deg);
}
