@import 'src/styles/variables';
@import 'src/styles/mixins';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 100px;
  padding: 16px;
  font-family: $montserratFontStyle;
  font-weight: $semiBoldFontWeight;
  letter-spacing: -0.2px;
  outline: none;
  border: none;
  cursor: pointer;
  @include grossFont;
}

.primary {
  background: $primary100;
  color: $white;

  &:hover {
    background: $gradient;
  }

  &:disabled {
    background: $primary20;
    cursor: not-allowed;
  }
}

.primary.disabledForRequest {
  background: $primary20;
  cursor: pointer;
}

.secondary {
  background: $white;
  color: $primary100;
  border: 1px solid $primary100;

  &:disabled {
    color: $primary40;
    border: 1px solid $primary40;
    cursor: not-allowed;
  }
}

.gradient {
  padding: 8px 21px;
  background-color: transparent;
  @include middleFont;

  .title {
    background: $gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    background: $gradient;

    .title {
      background-color: transparent;
      color: $white;
      -webkit-text-fill-color: $white;
    }

    svg path {
      fill: $white;
    }
  }
}

.gradient.active {
  background: $gradient;

  .title {
    background-color: transparent;
    color: $white;
    -webkit-text-fill-color: $white;
  }

  svg path {
    fill: $white;
  }
}

.gradientWithNoIcon {
  padding: 8px 21px;
  background: $gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include middleFont;
}

.titleWithIcon {
  margin-left: 8px;
}
