@import 'src/styles/variables';
@import 'src/styles/mixins';

.numberInput {
  position: relative;
  padding-bottom: 22px;
}

.input {
  @include input;
}

.labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;
}

.label {
  @include inputLabel;
  display: flex;
  align-items: center;
  color: $black60;
}

.error {
  border: 1px solid $red100;
}

.tooltip {
  margin-left: 4px;
}

.subLabel {
  font-family: $montserratFontStyle;
  font-weight: $regularFontWeight;
  letter-spacing: -0.7px;
  color: $black60;
  @include smallFont;
}

.errorLabel {
  color: $red100;
}

.errorMessage {
  position: absolute;
  bottom: 0;
}

@media print {
  .input {
    min-height: 27px;
    padding: 9px 13px;
    @include smallFont;
  }

  .label {
    @include smallFont;
  }

  .labelContainer {
    margin-bottom: 5px;
  }

  .numberInput {
    padding-bottom: 16px;
  }
}
