@import 'src/styles/mixins';

.container {
  padding: 9px 8px;
  font-family: $montserratFontStyle;
  font-style: normal;
  font-weight: $regularFontWeight;
  letter-spacing: -0.5px;
  color: $black100;
  @include hugeFont;
}
