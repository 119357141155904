@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  font-family: $montserratFontStyle;
  font-weight: $semiBoldFontWeight;
  text-transform: uppercase;
  color: $white;
  padding: 2px 6px;
  background: $primary100;
  border-radius: 4px;
  max-height: 16px;
  min-width: 61px;
  box-sizing: border-box;
  @include tinyFont;
}
