@import 'src/styles/media';
@import 'src/styles/variables';

.headerContainer {
  padding: 32px;
  background: transparent;

  @include xl {
    padding: 16px 0;
    background: $white;
    box-shadow: 0 8px 24px -6px rgba(0, 55, 140, 0.08), 0px 0px 1px rgba(0, 55, 140, 0.2);
    height: 50px;
  }

  .headerLogo {
    width: 190px;

    @include xl {
      width: 239px;
      margin: 0 auto;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }
}

.headerInBlock {
  padding: 32px 0;
  background: transparent;

  .headerLogo {
    width: 190px;

    @include xl {
      width: 280px;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }
}
