@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  @include filterContainer;
  justify-self: center;
  max-width: 495px;
}

.sectionContainer {
  margin-bottom: 24px;
}

.checkboxesContainer {
  @include sm {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }
}

.sectionTitle {
  @include h4;
  color: $black100;
  margin-bottom: 16px;
}

.checkbox:not(:last-child) {
  margin-bottom: 16px;
}

.radioButton:not(:last-child) {
  margin-bottom: 16px;
}

.separator {
  margin-bottom: 24px;
}
