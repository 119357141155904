@import 'src/components/PrequalificationResultPDF/PrequalificationResultPDF.module.scss';

.termsBoxContainer {
  padding: 10px 0;
  display: grid;
  grid-template-columns: 20% 35% 20%;
  gap: 24px;
  margin-bottom: 9px;
  border-bottom: 1px solid $primary20;
}

.disclaimer {
  padding: 10px;
  background-color: $primary10;
  font-family: $montserratFontStyle;
  font-weight: $regularFontWeight;
  color: $black100;
  @include smallFont;
}

.contentSectionTitle {
  font-family: $montserratFontStyle;
  font-weight: $boldFontWeight;
  letter-spacing: -0.2px;
  color: $black100;
  margin-bottom: 16px;
  @include grossFont;
}

.pricingContainer {
  padding-top: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 42px;
}

.singlePricingContainer {
  @extend .pricingContainer;

  grid-template-columns: 1.2fr 0.8fr;
}

.content:not(:last-child) {
  margin-bottom: 40px;

  @include md {
    margin-bottom: 0;
  }
}

.disclaimerBox {
  margin-left: 0;
}
