@import 'src/styles/mixins';

@media all {
  .pageBreak {
    display: none;
  }
}

.container {
  display: none;
  padding: 10px 16px;
}

.pageInfo {
  font-family: $montserratFontStyle;
  font-weight: $regularFontWeight;
  letter-spacing: -0.7px;
  color: $black60;
  @include hugeFont;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.header {
  @include h2;
}

@media print {
  @page {
    size: B4 portrait;
  }

  .container {
    display: block;
  }

  .pageBreak {
    margin-top: 1rem;
    display: block;
    clear: both;
    page-break-before: always;
  }
}

.detailsRow {
  font-size: 14px;
}
