@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  @include filterContainer;
  justify-self: center;
  max-width: 584px;
}

.sectionContainer {
  margin-bottom: 24px;
}

.sectionTitle {
  @include h4;
  color: $black100;
  margin-bottom: 16px;
}

.sectionFiltersContainer {
  @include sm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
  }
}

.variablesSection {
  margin-bottom: 24px;
}

.separator {
  margin-bottom: 24px;
}
