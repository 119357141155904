@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;
}

.title {
  font-family: $montserratFontStyle;
  font-weight: $semiBoldFontWeight;
  letter-spacing: -0.2px;
  margin-left: 8px;
  background: $gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include middleFont;
}

.backArrowLink {
  & stop:first-child {
    stop-color: $gradientFirstColor;
  }
  & stop:last-child {
    stop-color: $gradientSecondColor;
  }
}
