@import 'src/styles/variables';
@import 'src/styles/mixins';

.footer {
  margin-top: 96px;

  @include xl {
    margin-top: 72px;
  }
}

.container {
  background: transparent;
}

.copyright {
  font-family: $montserratFontStyle;
  font-weight: $regularFontWeight;
  letter-spacing: -0.7px;
  color: $black60;
  @include smallFont;
}

.ehoLogo {
  margin-top: 8px;
}

.link {
  text-decoration: none;
  color: $primary100;
}

.clock {
  margin-top: 8px;
}

.insidePageFooter {
  box-sizing: border-box;
  padding: 32px;
  max-width: 1352px;
  margin: 0 auto;

  @include xl {
    padding: 8px 32px 16px;
  }
}
