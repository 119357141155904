@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  width: 100%;
}

.loanCriteriaContainer {
  @include formSectionContainer;
}

.sectionTitle {
  @include h3;
  color: $black100;
  margin-bottom: 16px;
}

.inputs {
  flex-grow: 1;
}

.formInput {
  margin-bottom: 8px;
}

.formInput:last-child {
  @include xl {
    margin-bottom: 0;
  }
}

.reservesNotification {
  font-family: $montserratFontStyle;
  font-weight: $regularFontWeight;
  letter-spacing: -0.7px;
  color: $black60;
  margin-top: 4px;
  @include smallFont;
  margin-bottom: 30px;
}

@media print {
  .loanCriteriaContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }

  .inputs {
    & > * {
      padding-bottom: 8px;
    }
  }

  .reservesNotification {
    margin: 0px;
    @include smallFont;
  }
}
