@import 'src/styles/variables';
@import 'src/styles/mixins';

.tableContainer {
  @include md {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
  }
}

.llpaContainer {
  height: max-content;
  width: 100%;
  max-width: 298px;
  background: $white;
  border: 1px solid $primary20;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;

  @include md {
    padding: 10px 16px;
  }
}

.popup {
  display: none;
  padding: 0;
  overflow-y: unset;
}

.popupActive {
  display: flex;
  align-items: center;
  justify-content: center;
}

.llpsDisablePopupOnDesktop {
  @include md {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: sticky;
    top: 16px;
    background: transparent;
    height: max-content;
  }
}

.popupHeader {
  @include md {
    display: none;
  }
}

.desktopTitle {
  display: none;
  font-family: $montserratFontStyle;
  font-weight: $boldFontWeight;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: $black60;
  margin: 0 0 16px 16px;
  @include smallFont;

  @include md {
    display: block;
  }
}

@media print {
  .tableContainer {
    display: grid;
    grid-template-columns: 0.9fr 1.1fr;
    grid-column-gap: 32px;
  }

  .llpaContainer {
    padding: 10px 16px;
  }

  .llpsDisablePopupOnDesktop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: sticky;
    top: 16px;
    background: transparent;
    height: max-content;
  }

  .popupHeader {
    display: none;
  }

  .desktopTitle {
    display: block;
  }

  .llpaContainer {
    padding: 6px 9px;
  }
}
