@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  background: $white;
  border: 1px solid $primary20;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 20px 12px;
  cursor: pointer;

  @include lg {
    @include productTableRowContainer;
    display: grid;
    grid-template-columns: $productsTableGridColumnSize;

    &:hover {
      background: $primary6;
    }
  }
}

.retailPortalContainer {
  @extend .container;

  @include lg {
    grid-template-columns: $productsTableGridColumnSize;
  }
}

.variableContainer {
  margin-bottom: 12px;

  @include lg {
    margin-bottom: 0;
  }
}

.variableName {
  @include productTableVariableName;
}

.loanProductTitle {
  font-family: $ralewayFontStyle;
  font-weight: $boldFontWeight;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: $black100;
  @include grossFont;

  @include lg {
    font-style: normal;
    font-weight: $regularFontWeight;
    font-family: $montserratFontStyle;
    letter-spacing: -0.5px;
    @include grossFont;
  }
}

.variableValue {
  @include productTableVariableValue;
  display: flex;
}

.separator {
  @include lg {
    display: none;
  }
}

.variableButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $primary100;
  border-radius: 100px;
  padding: 8px;
  box-sizing: border-box;
  margin-top: 12px;

  @include lg {
    justify-content: flex-start;
    border: none;
    border-radius: 0;
    padding: 0;
    margin-top: 0;
  }
}

.button {
  width: 100%;
  padding: 8px;
  min-width: 126px;
}

.testFieldIcon {
  margin-right: 4px;
  & > path {
    fill: $primary100;
  }
}

.llpAndPricing {
  font-family: $montserratFontStyle;
  font-weight: $semiBoldFontWeight;
  letter-spacing: -0.2px;
  color: $primary100;
  @include middleFont;

  @include lg {
    @include productTableVariableValue;
    color: $primary100;
  }
}

.negativePoint {
  color: $green100;
}
