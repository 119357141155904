@import 'src/styles/variables';

.container {
  background: linear-gradient(160.65deg, rgba(248, 250, 252, 0.5) 46.35%, #EEF2F7 100%), $white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
}
