@import 'src/components/PrequalificationResultPDF/PrequalificationResultPDF.module.scss';

.loanCriteria {
  margin-bottom: 0;
}

.creditProfile {
  margin-bottom: 20px;

  @include xl {
    margin-bottom: 0;
  }
}

.propertyInformation {
  margin-bottom: 20px;

  @include xl {
    margin-bottom: 0;
  }
}

.bottomBar {
  margin-bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
}
