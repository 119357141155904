@import 'src/styles/media';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include xl {
    flex-direction: row;
  }
}

.leftBarContainer {
  @include lg {
    flex-grow: 1;
  }
}
